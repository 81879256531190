@import "styles/utils.module";
@import "styles/color.module";

.root {
  display: grid;
  gap: 8px;  // gap is usable in display: grid.
}

.column_1 {
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;  // gap is usable in display: grid.
}

.column_2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 8px;  // gap is usable in display: grid.
  padding: 0 16px;
}

.column_3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 8px;  // gap is usable in display: grid.
}

.hideTextRoot {
  gap: 8px;  // gap is usable in display: grid.
}
