@import "styles/utils.module.scss";
@import "styles/color.module";

@each $name, $map in $themes {
  .theme-#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}

.productsWrapper {
  @extend .flexRow;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  & > * + * {
    margin-left: 4px;
  }
}

.cardWrapper {
  text-align: left;
  flex: 0 0 135px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.showMoreContainer {
  flex: 0 0 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.showMoreText {
  width: 100%;
  margin-top: 12px;
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
}

.horizontalScrollPaddingLeft {
  flex: 0 0 $layoutLeftPaddingNew - 4px;
}

.horizontalScrollPaddingRight {
  flex: 0 0 $layoutRightPaddingNew - 4px;
}

.swiperSliderDescriptionWrapper {
  display: flex;
  align-items: center;
  background-color: var(--brand);
  text-align: center;
  justify-content: center;
  height: 22px;
  color: var(--typo3);
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}

.swiper_container {

}

