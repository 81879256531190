@import "components/layout/layout.module";
@import "styles/utils.module";
@import "styles/color.module";

@each $name, $map in $themes {
  .theme-#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}

.sectionContainer {
  //padding-bottom: 40px;
}

.sectionTitleWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 $layoutRightPaddingNew 12px $layoutLeftPaddingNew;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.foldedTitle {
  margin-bottom: 0;
}

.sectionTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;

  color: var(--gray800);
}

.sectionDescription {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;

  color: var(--gray500);
}

.productsWrapper {
  @extend .flexRow;
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto;
  & > *+* {
    margin-left: 9px;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cardWrapper {
  text-align: left;
  flex: 0 0 135px;
}

.horizontalScrollPaddingLeft {
  flex: 0 0 $layoutLeftPaddingNew - 4px;
}

.horizontalScrollPaddingRight {
  flex: 0 0 $layoutRightPaddingNew - 4px;
}

.arrowRightIcon {

}

.foldIcon {
  transform: rotate(-90deg);
}

.unfoldIcon {
  transform: rotate(-270deg);
}

.gridContainer {
}

.bannerButtonContainer {
  @extend .buttonContainer;
  margin-top: 16px !important;
}
.buttonContainer {
  margin-top: 20px;
  padding: 0 16px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonIcon {
  width: 20px;
  height: 20px;

  & > path {
    fill: var(--fullButtonBackgroundBroder);
  }
}

.linkText {
  color: var(--brand);
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
}
