@import "styles/utils.module";

.rootWrapper {
    @extend .flexRow;
    @extend .justifyContentCenter;
    background-color: white;
    //min-height: 100vh;
    padding-bottom: calc(env(safe-area-inset-bottom));
}

.rootBody {
    background-color: white;
    position: relative;
    max-width: $mobileMaxWidth;
    width: 100%;
}

.root {
    flex: 1;
    display: block;
}

.restrictWidth {
    max-width: calc(100% - $navBarWidthDesktop);
}

.container {
    margin-top: $topPadding;
    flex: 1;
    padding-left: $layoutLeftPadding;
    padding-right: $layoutRightPadding;
    display: block;
}

@include tablet {
    .restrictWidth {
        max-width: calc(100% - $navBarWidthTablet);
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

@include mobile {
    .restrictWidth {
        max-width: none;
    }
    .container {
        margin-top: 0;
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backToHome {
    margin: 3rem 0 0;
}

:export {
    layoutLeftPadding: $layoutLeftPadding
}

.bottomPadding {
    padding-bottom: $mobilNavBarHeight;
}
