@import "styles/utils.module";
@import "styles/color.module";

.productsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;
}

.column_1 {
  & > * {
    margin-top: 16px;
  }
}

.column_2 {
  & > * {
    width: calc(50% - 1.5px); /* 3 items per row, minus gap */
    box-sizing: border-box;

    &:nth-child(2n) {
      margin-left: 3px;
    }
    margin-top: 16px;
  }
}

.column_3 {
  & > * {
    width: calc(33.333% - 2px); /* 3 items per row, minus gap */
    box-sizing: border-box;

    &:nth-child(3n+2) {
      margin-left: 3px;
    }

    &:nth-child(3n) {
      margin-left: 3px;
    }
    margin-top: 16px;
  }
  padding: 0;
}
