@import "styles/color.module";

@each $name, $map in $themes {
  .theme-#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}

.container {
  flex: 0 0 auto;
  padding: 8px 12px;
  background-color: var(--gray100);
  border: none;
  border-radius: 20px;

  span {
    color: var(--gray800);
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
  }
  &:last-of-type {
    margin-right: 16px;
  }
}

.selected {
  background-color: var(--brand);

  span {
    color: white;
  }
}

.unSelected {
  background-color: var(--gray100);
  span {
    color: var(--unSelectedBulletText);
  }
}

