

.sectionContainer {
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 16px;
  }
}

.categoryListContainer {
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  & > * + * {
    margin-left: 8px;
  }
}
.categoryListContainer::-webkit-scrollbar {
  display: none;
}
