@import "components/layout/layout.module";
@import "styles/utils.module";
@import "styles/color.module";

@each $name, $map in $themes {
  .theme-#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}

.sectionContainer {
  padding: 0;
}

.sectionTitleWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 $layoutRightPaddingNew 12px $layoutLeftPaddingNew;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.column {
  grid-template-rows: 1fr 1fr 1fr;
}


.foldedTitle {
  margin-bottom: 0;
}

.sectionTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;

  color: var(--gray800);
}

.sectionDescription {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;

  color: var(--gray500);
}

.verticalImage{
  width: 100%;
  display: block;
}

.overlay {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(25, 25, 25, 0.2) 0%, rgba(25, 25, 25, 0) 57.81%);
}

.verticalSwiperWrapper {
  position: relative;
  left: 0;
  right: 0;
  display: block;
}

.productsWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
}

.hiddenSwiper {
  display: none;
}

.cardWrapper {
  text-align: left;
  flex: 0 0 135px;
}

.horizontalScrollPaddingLeft {
  flex: 0 0 $layoutLeftPaddingNew - 4px;
}

.horizontalScrollPaddingRight {
  flex: 0 0 $layoutRightPaddingNew - 4px;
}

.arrowRightIcon {

}

.foldIcon {
  transform: rotate(-90deg);
}

.unfoldIcon {
  transform: rotate(-270deg);
}

.gridContainer {
}

.buttonContainer {
  margin-top: 20px;
  padding: 0 16px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonIcon {
  width: 20px;
  height: 20px;

  & > path {
    fill: var(--fullButtonBackgroundBroder);
  }
}

.linkText {
  color: var(--brand);
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
}
