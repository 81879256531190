


.root {
  margin-top: 40px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 80px;
  }
  overflow: hidden;
}
